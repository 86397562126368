import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function FotovoltaicoSuCopertura() {

    return (
        <div>
            <MetaDecorator
                title="Fotovoltaico su Copertura"
                description="Da oltre 10 anni MGM Solar è specializzata nello sviluppo, progettazione e realizzazione di impianti fotovoltaici fornendo servizi di manutenzione professionale per assicurarne il funzionamento ottimale nel tempo."
                keywords="fotovoltaico, copertura, fotovoltaico su copertura, tetto a botte, tetto piano, tetto a falde"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/WK2knHM/fotovoltaicocopertura-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-5 pt-3">
                    <h1 className="fw-bold">Fotovoltaico su Copertura</h1>
                </div>
                <div className="pb-5">
                    <p className="mb-5 text-justify">
                        <p>Installare un impianto fotovoltaico è ormai (must) una scelta d’obbligo per tutte le aziende che vogliono risparmiare sull’energia elettrica e ridurre il più possibile l’impatto ambientale della propria attività. Con il rapido aumento del prezzo della corrente il tempo di rientro dell’investimento si è dimezzato, arrivando anche ad meno di 3 anni.
                            Da oltre 10 anni  MGM Solar è specializzata nello <b>sviluppo, progettazione e realizzazione di impianti fotovoltaici</b> fornendo servizi di manutenzione professionale per assicurarne il funzionamento ottimale nel tempo.
                            MGM Solar accompagna cliente dalle pratiche burocratiche necessarie per poter accedere alle agevolazioni del GSE fino all’allaccio del suo impianto alla rete elettrica, utilizzando le migliori tecnologie disponibili sul mercato.
                        </p>
                        <p>
                            Grazie all’ampia gamma di accessori e supporti disponibili è possibile <b>installare impianti su qualsiasi tetto</b>, nel totale rispetto delle normative europee e fornitura finale delle certificazioni richieste dalla legge.
                        </p>
                    </p>
                </div>
                <Row>
                    <Col xs={12} lg={4} className="mb-5 text-center">
                        <img
                            src={"https://i.ibb.co/64HcvV3/tettoafalde.jpg"}
                            alt="img_servizio_01"
                            className="mb-4 img4corners"
                        />
                        <div>
                            <h3 className="fw-bold">Tetto a falde</h3>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="mb-5 text-center">
                        <img
                            src={"https://i.ibb.co/KwpwsZc/tettopiano.jpg"}
                            alt="img_servizio_01"
                            className="mb-4 img4corners"
                        />
                        <div>
                            <h3 className="fw-bold">Tetto piano</h3>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="mb-5 text-center">
                        <img
                            src={"https://i.ibb.co/BLp9Vcf/tetto-a-botte.jpg"}
                            alt="img_servizio_01"
                            className="mb-4 img4corners"
                        />
                        <div>
                            <h3 className="fw-bold">Tetto a botte</h3>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    )
}
