import './home.css'
import {Navbar} from "../../Navbar/Navbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {Link} from "react-router-dom";
import logoMGMwhite from "../../imgs/logo/logo-mgm-white.png";
import {MetaDecorator} from "../../utils/MetaDecorator";
import {useState} from "react";
import {
    FaDollarSign,
    FaGlobeAmericas,
    FaSolarPanel,
    FaStar,
    FaVolumeMute,
    FaVolumeUp
} from "react-icons/fa";
import GrenkeLogo from '../../imgs/sponsor/grenke-logo.png';
import IntesaSanPaoloLogo from '../../imgs/sponsor/intesasanpaolo-logo.png';
import ProgettoBancaLogo from '../../imgs/sponsor/progettobanca-logo.png';
import {VideoBG} from "../../utils/VideoBG";


export function Home() {

    const [mutedVideo, setMutedVideo] = useState(true)

    return (
        <div>
            <MetaDecorator
                title="Home"
                description="MGM solar propone soluzioni integrate per la produzione di energia elettrica concepite nel rispetto delle risorse naturali ed in modo efficiente dal punto di vista economico."
                keywords="home, homepage, landing page"
            />
            <Navbar />
            <div className="header">
                <VideoBG src="https://theplaceaps.000webhostapp.com/videoCoverHomeMgm.mp4" className="video-header bg-dark" />
                {
                    /*

                <VideoBG src="http://media.mgmsolar.it/media/video/videoCoverHomeMgm.mp4" className="video-header bg-dark" />

                    <video controls={false} muted loop autoPlay="autoplay" className="video-header bg-dark"
                       poster="https://i.ibb.co/Mh1jqMv/vlcsnap-2022-09-19-20h02m33s007.png"
                >
                    <source src="https://media.mgmsolar.it/media/video/videoCoverHomeMgm.mp4" type="video/mp4"/>
                </video>
                     */
                }

                <span
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                    }}
                    className="py-3 px-4 opacity-75 d-none"
                >
                    {
                        mutedVideo ?
                            <FaVolumeMute
                                color="#fff"
                                size="2em"
                                className="iconHover"
                                onClick={() => setMutedVideo(false)}
                            />
                            :
                            <FaVolumeUp
                                color="#fff"
                                size="2em"
                                className="iconHover"
                                onClick={() => setMutedVideo(true)}
                            />
                    }
                </span>
            </div>
            {
                /*
            <div className="header d-block d-lg-none" style={{backgroundImage:'url("https://i.ibb.co/Mh1jqMv/vlcsnap-2022-09-19-20h02m33s007.png")', backgroundSize:'cover', backgroundPosition:'center'}}>
                <div
                    style={{
                        width:'100%',
                        height:'100%',
                        backdropFilter:'brightness(50%) blur(3px)'
                    }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <img
                        src={logoMGMwhite}
                        alt="Logo MGM"
                        className="imgCol"
                    />
                </div>
            </div>
                 */
            }
            <div className="bg-light py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'70vh'}}>
                <Container className="py-5">
                    <Row>
                        <Col sm={12} lg={6} className="d-flex justify-content-start align-items-center">
                            <div className="text-center text-lg-start">
                                <h2 className="fw-bold mb-5">Chi Siamo</h2>
                                <div className="mb-5">
                                    <p>MGM solar nasce dopo 15 anni di esperienza nel settore delle energie rinnovabili. Oggi è specializzata nella <b>progettazione, installazione e collaudo di impianti fotovoltaici di grossa taglia</b>.</p>
                                    <br/>
                                    <p>Grazie a un team di oltre 50 persone Mgm Solar accompagna ogni i clienti in tutte le fasi garantendo inoltre un servizio professionale di <b>o&m manutenzione</b> e <b>assistenza post-vendita</b>.</p>
                                </div>
                                <Link to="/chi-siamo">
                                    <Button
                                        className="rounded10"
                                        variant="dark"
                                    >
                                        Leggi di più
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col sm={12} lg={6} className="d-flex justify-content-center align-items-center d-none d-lg-block">
                            <img
                                src="https://i.ibb.co/JtsxzbM/chisiamo-pic.png"
                                alt="Chi Siamo"
                                style={{width:'40vw'}}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-green py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container className="py-5">
                    <div className="mb-5 text-light">
                        <h2 className="mb-2">Operiamo nel Fotovoltaico da oltre <b><u>15 anni</u></b></h2>
                    </div>
                    <Row className="pt-5">
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center mb-5 mb-lg-0">
                            <div className="text-light">
                                <FaStar size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Anni attività</p>
                                <h2 className="fw-bold">15+</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center mb-5 mb-lg-0">
                            <div className="text-light">
                                <FaSolarPanel size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Impianti installati</p>
                                <h2 className="fw-bold">1,5k</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center mb-5 mb-md-0">
                            <div className="text-light">
                                <FaDollarSign size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Risparmio annuo</p>
                                <h2 className="fw-bold">3 MLN €</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center">
                            <div className="text-light">
                                <FaGlobeAmericas size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Risparmio CO2</p>
                                <h2 className="fw-bold">30k T</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-light py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'80vh'}}>
                <Container className="py-5">
                    <Row>
                        <Col sm={12} lg={6} className="d-flex justify-content-start align-items-center mb-5 mb-lg-0">
                            <div className="text-center text-lg-start">
                                <h2 className="fw-bold mb-5">Servizi</h2>
                                <div className="mb-5">
                                    <h4><b>ESPERIENZA A SERVIZIO DELLA VOSTRA ENERGIA</b></h4>
                                    <br/>
                                    Da oltre 10 anni costruiamo, gestiamo e proteggiamo i vostri impianti.
                                    <br/>
                                    Mgm Solar è un <b>partner affidabile a cui rivolgersi</b> per utilizzare energia pulita in casa, investire nel solare con la propria azienda, efficientare i consumi energetici e ridurre i costi in bolletta: <b>scopri l’ampia offerta di servizi di eccellenza</b>, per un futuro migliore possibile già da oggi.                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={6} className="text-center text-lg-end">
                            <img
                                src="https://i.ibb.co/BKBD1qf/pic-servizi.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh', backgroundColor:'#c3cbc9'}}>
                <Container>
                    <div className="py-5">
                        <Row className="mt-5">
                            <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                                <img
                                    src="https://i.ibb.co/TWdBvpQ/scopri-noleggio.jpg"
                                    alt="Chi Siamo"
                                    className="img4corners imgBig"
                                />
                            </Col>
                            <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                                <div className="mb-5 mb-lg-0 w-100">
                                    <h2 className="fw-bold mb-5">Scopri il <br/><u>Noleggio Operativo</u></h2>
                                    <Link to="/servizi/noleggio-operativo">
                                        <Button
                                            variant="dark"
                                            className="rounded10"
                                        >
                                            Leggi di più
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="bg-light py-4 text-center d-flex justify-content-center align-items-center" style={{minHeight:'15vh'}}>
                <Container>
                    <h3 className="fw-bold my-4">Partner finanziari</h3>
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={4}>
                            <a href="https://www.grenke.it/" target="_blank" rel="noreferrer">
                                <img
                                    src={GrenkeLogo}
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4}>
                            <a href="https://www.intesasanpaolo.com/" target="_blank" rel="noreferrer">
                                <img
                                    src={IntesaSanPaoloLogo}
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <a href="https://www.bancaprogetto.it/" target="_blank" rel="noreferrer">
                                <img
                                    src={ProgettoBancaLogo}
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-green py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'40vh'}}>
                <Container className="py-5 text-light">
                    <div>
                        <h5 className="mb-2">Richiedi subito il tuo</h5>
                        <h2 className="fw-bold mb-5">Preventivo Gratuito!</h2>

                        <Link to="/contatti">
                            <Button
                                className="rounded10"
                                variant="light"
                            >
                                Contattaci
                            </Button>
                        </Link>
                    </div>
                </Container>
            </div>
            <div className="bg-light py-4 text-center d-flex justify-content-center align-items-center" style={{minHeight:'15vh'}}>
                <Container>
                    <h3 className="fw-bold my-4">Partner tecnologici</h3>
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.bosch.it/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/2qmRT53/bosch-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://ita.sungrowpower.com/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/nLKLNvL/sungrow-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.jasolar.com/html/italian/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/qY2JzQQ/jasolar-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.lgensol.com/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/5Yj1KnX/lg-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.fronius.com/it-it/italy" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/pJLYhJC/fronius-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.sma-italia.com/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/SnQJV8n/sma-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.solaredge.com/it" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/bNDKSpm/solaredge-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://it.suntech-power.com/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/mDyw3SC/suntech-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.longi.com/en/" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/ynSJXvm/longi-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.tesla.com/it_it/powerwall" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/QfST4rH/tesla-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://wallbox.com/it_it" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/54QpCT4/wallbox-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <a href="https://www.daikin.it/it_it/privati.html" target="_blank" rel="noreferrer">
                                <img
                                    src="https://i.ibb.co/cxSnckk/daikin-logo.png"
                                    alt="Partner"
                                    style={{height:'100px'}}
                                    className="logoPartner"
                                />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-light">
                <Footer />
            </div>
        </div>
    )
}
