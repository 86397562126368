import {Navbar} from "../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../Footer/Footer";
import ImgFrameVerde from "../imgs/frame_green.png";
import {MetaDecorator} from "../utils/MetaDecorator";


export function AziendeAgricole() {

    return (
        <div>
            <MetaDecorator
                title="Aziende Agricole"
                description="Hai un’azienda agricola e vorresti installare un fotovoltaico per tagliare le spese dell’elettricità? Saggia scelta: azienda agricola+fotovoltaico è un binomio che funziona benissimo, anche grazie agli incentivi."
                keywords="aziende agricole, pnrr, fondi pnrr, beneficiari pnrr, risparmio economico"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/pbz2Qk9/azagricoletop.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Aziende Agricole</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Fondi del PNRR</h2>
                                <p className="mb-5">
                                    <p>Il Ministero delle Politiche agricole alimentari e forestali (MIPAAF) ha pubblicato il decreto del 25 marzo 2022 recante le direttive necessarie all'avvio della misura "Parco Agrisolare", a cui sono dedicate risorse pari a 1,5 miliardi di euro a valere sui fondi del PNRR.</p>
                                    <br />
                                    <p>Il nuovo bando finanzia interventi da realizzare sui tetti di fabbricati strumentali all’attività agricola, zootecnica e agroindustriale, che prevedano l’installazione di impianti fotovoltaici, con potenza di picco non inferiore a 6 kWp e non superiore a 500 kWp.</p>
                                    <br />
                                    <p>Possono inoltre essere eseguiti uno o più dei seguenti interventi di riqualificazione ai fini del miglioramento dell’efficienza energetica delle strutture:</p>
                                    <br />
                                    <ul>
                                        <li>Rimozione e smaltimento dell’amianto</li>
                                        <li>Realizzazione dell’isolamento termico dei tetti</li>
                                        <li>Realizzazione di un sistema di aerazione connesso alla sostituzione del tetto</li>
                                    </ul>
                                    <br />
                                    <p>Previsto un contributo a fondo perduto <b>fino al 50%</b> con un <b>incremento fino al 20%</b> in presenza di specifici requisiti.</p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/SVsYrQc/azagricole1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/9pVhLJz/azagricole2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Beneficiari del PNRR</h2>
                                <p className="mb-5">
                                    <p>Possono accedere alle risorse:</p>
                                    <br />
                                    <ul>
                                        <li>Imprenditori agricoli, in forma individuale o societaria</li>
                                        <li>Imprese agroindustriali, in possesso di codice ATECO indicati in un avviso di prossima pubblicazione</li>
                                        <li>Indipendentemente dai propri associati, le cooperative agricole che svolgono attività di cui all’art. 2135 del codice civile e le cooperative o loro consorzi di cui all’art. 1, comma 2, del decreto legislativo 18 maggio 2001, n. 228.</li>
                                    </ul>
                                    <br />
                                    <p>Sono esclusi i soggetti esonerati dalla tenuta della contabilità IVA, aventi un volume di affari annuo inferiore ad euro 7.000,00.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Rendi la tua azienda agricola ancora più green</h2>
                                <p className="mb-5">
                                    <p>Hai un’azienda agricola e vorresti installare un fotovoltaico per tagliare le spese dell’elettricità? Saggia scelta: azienda agricola+fotovoltaico è un binomio che funziona benissimo, anche grazie agli incentivi. In T-Green installiamo impianti fotovoltaici per aziende agricole di tutte le dimensioni: ti spieghiamo tutti i dettagli del nostro servizio.</p>
                                    <br />
                                    <p>Installare il fotovoltaico, per un’azienda agricola, non è solo <b>una scelta economica</b>, ma anche e soprattutto <b>ecologica</b>. <b>Generare energia pulita</b> è un modo di rendere tributo a quella terra da cui traiamo beneficio, oltre ad essere la soluzione più efficiente per <b>tagliare le spese della bolletta</b>.</p>
                                    <br />
                                    <p>Il fotovoltaico per aziende agricole ha le stesse caratteristiche e lo stesso funzionamento di un fotovoltaico residenziale; cambiano, invece, l’estensione e la resa energetica totale dell’impianto.</p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/rcmqrM7/azagricole3.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
