import {Helmet} from "react-helmet";


export function MetaDecorator({title, description, keywords}) {

    const basicTags = 'mgm solar, mgm, mgmsolar, solar, fotovoltaico, pannelli fotovoltaici, pannello fotovoltaico, rimozione eternit, bonus 110, rimozione amianto, colonnine ricarica, sistema accumulo, serramenti, infissi, ispezione pannelli drone, termografia pannelli, energia rinnovabile, energia, consumo energetico, preventivo, preventivo gratis, mondovi, mondovì, torino, cuneo'

    return (
        <Helmet>
            <title>{title + ' | MGM solar'}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords + ', ' + basicTags} />
        </Helmet>
    )
}
