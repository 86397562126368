import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function SerramentiEdInfissi() {

    return (
        <div>
            <MetaDecorator
                title="Serramenti ed Infissi"
                description="I serramenti e gli infissi sono dei veri e propri oggetti di design estremamente importanti nell' ammortizzare il suono e gli sbalzi climatici. Proprio per questo non possiamo accontentarci di prodotti con qualità scadente o con materiali non rifiniti né di alta qualità. Esistono numerosi tipi di materiali, forme e spessori."
                keywords="serramenti, infissi, pvc, legno, alluminio, durevole, solido, robusto"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/CQDnBxc/serramentitop1.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-5 pt-3">
                    <h1 className="fw-bold">Serramenti ed Infissi</h1>
                </div>
                <div className="py-5">
                    <p className="mb-5 text-justify">
                        I serramenti e gli infissi sono dei veri e propri oggetti di design estremamente importanti nell' ammortizzare il suono e gli sbalzi climatici.
                        Proprio per questo non possiamo accontentarci di prodotti con qualità scadente o con materiali non rifiniti né di alta qualità.
                        Esistono numerosi tipi di materiali, forme e spessori...
                    </p>
                </div>
                <Row>
                    <Col md={4} className="mb-5 text-center">
                        <img
                            src="https://i.ibb.co/WgPcQM6/infissipvc.png"
                            alt="img_servizio_01"
                            className="mb-4 imgColRespSmall"
                        />
                        <div>
                            <h3 className="fw-bold">Infissi in PVC</h3>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5 text-center">
                        <img
                            src="https://i.ibb.co/z5vhjmc/infissilegno.png"
                            alt="img_servizio_01"
                            className="mb-4 imgColRespSmall"
                        />
                        <div>
                            <h3 className="fw-bold">Infissi in Legno</h3>
                        </div>
                    </Col>
                    <Col md={4} className="mb-5 text-center">
                        <img
                            src="https://i.ibb.co/NSTyCys/infissialluminio.png"
                            alt="img_servizio_01"
                            className="mb-4 imgColRespSmall"
                        />
                        <div>
                            <h3 className="fw-bold">Infissi in Alluminio</h3>
                        </div>
                    </Col>
                </Row>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/WgPcQM6/infissipvc.png"
                                alt="Chi Siamo"
                                className="imgColResp"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <div className="mb-5">
                                    <h2 className="fw-bold">Infissi in PVC</h2>
                                </div>
                                <p className="mb-3">
                                    Il PVC ha molti vantaggi che lo rendono il materiale perfetto da utilizzare per i serramenti.
                                    Offre buone prestazioni e un’eccellente durata, richiedendo pochissima manutenzione. La sua efficienza termica e l’isolamento acustico lo rendono ottimo in termini di rapporto qualità/prezzo.
                                    È noto per fornire il massimo livello di comfort termico oltre ad essere estremamente resistente e duraturo.
                                </p>
                                <p className="text-center text-lg-start">
                                    Caratteristiche principali:
                                    <ul>
                                        <li>Basso costo</li>
                                        <li>Manutenzione ridotta</li>
                                        <li>Grandi prestazioni</li>
                                        <li>Resistente e duraturo</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/z5vhjmc/infissilegno.png"
                                alt="Chi Siamo"
                                className="imgColResp"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <div className="mb-5">
                                    <h2 className="fw-bold">Infissi in Legno</h2>
                                </div>
                                <p className="mb-3">
                                    Gli infissi in legno sono molto versatili e sono perfetti sia per case moderne sia per casali più antichi.
                                    Il legno offre sicuramente la scelta migliore in termini di design, rispetto dell’ambiente e isolamento ma è sicuramente meno economico del PVC.
                                </p>
                                <p className="text-center text-lg-start">
                                    Caratteristiche principali:
                                    <ul>
                                        <li>Design più accattivante</li>
                                        <li>Sostenibilità</li>
                                        <li>Miglior durata nel tempo</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/NSTyCys/infissialluminio.png"
                                alt="Chi Siamo"
                                className="imgColResp"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <div className="mb-5">
                                    <h2 className="fw-bold">Infissi in Alluminio</h2>
                                </div>
                                <p className="mb-3">
                                    Gli infissi in alluminio offrono un’incredibile versatilità che permette di conferire loro qualsiasi tipologia in termini di forma e colore.
                                    L’alluminio assicura poca manutenzione, un peso leggero, buona resistenza e la possibilità di essere facilmente personalizzabili in base alle proprie esigenze
                                </p>
                                <p className="text-center text-lg-start">
                                    Caratteristiche principali:
                                    <ul>
                                        <li>Resistenza</li>
                                        <li>Leggerezza</li>
                                        <li>Versalitilità e personalizzazione</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/0hk5tPV/infissilegnoalluminio.png"
                                alt="Chi Siamo"
                                className="imgColResp"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <div className="mb-5">
                                    <h2 className="fw-bold">Infissi in Legno-Alluminio</h2>
                                </div>
                                <p className="mb-3">
                                    Gli infissi in legno-alluminio sono una tipologia di infissi ibrida che combina
                                    l’eleganza e l’isolamento del legno con la resistenza dell’alluminio.
                                </p>
                                <p className="text-center text-lg-start">
                                    Caratteristiche principali:
                                    <ul>
                                        <li>Design</li>
                                        <li>Leggerezza</li>
                                        <li>Isolamento impeccabile</li>
                                        <li>Durevolezza</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
