import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";
import ImgFrameVerde from "../../imgs/frame_green.png";


export function Fotovoltaico() {

    return (
        <div>
            <MetaDecorator
                title="Casa"
                description=""
                keywords=""
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/K2V3FjZ/casa-fotovoltaico-cove.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Fotovoltaico Residenziale</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Fotovoltaico a Casa Tua</h2>
                                <p className="mb-5">
                                    <p>La strada verso la sostenibilà parte da casa tua. Scopri tutte le nostre soluzioni per rendere più efficiente la tua casa, grazie all'energia del sole.</p>
                                    <br/>
                                    <p>L'installazione di pannelli fotovoltaici e innovativi sistemi di accumulo dell'energia,  ti offrono grandi vantaggi: hai la possibilità di autoprodurre energia, raggiungendo in questo modo l'indipendenza energetica e il risparmio in bolletta.</p>
                                    <br/>
                                    <p>MGM Solar si occupa della realizzazione di <b>impianti fotovoltaici chiavi in mano</b> residenziali o per condomini grazie a soluzioni su misura per ottenere <b>la massima resa e il maggiore risparmio possibile</b> in base alle esigenze di ogni cliente.</p>
                                    <br/>
                                    <p>Mgm Solar affianca ogni cliente durante l’intero processo, occupandosi in prima persona di <b>tutte le fasi progettuali</b>, dall’ analisi di fattibilità alla progettazione, dalla costruzione alle pratiche con il GSE e per i bonus statali.</p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/PzPsSFZ/fotocasa1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/T1XY8Rs/fotocasa2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">L'inesauribile e gratuita risorsa del sole</h2>
                                <p className="mb-5">
                                    <p>Dai un taglio al caro-energia: scegli l’energia pulita e prodotta in autonomia con il nostro impianto fotovoltaico. Calcola subito un preventivo!</p>
                                    <p>Sei pronto anche tu a rinnovarti con noi?</p>
                                    <br />
                                    <p>Tante case che producono, accumulano e consumano energia pulita prodotta con il loro impianto fotovoltaico grazie all’inesauribile e gratuita risorsa del sole.</p>
                                    <br />
                                    <p>Questo è il futuro che vogliamo e che, insieme a voi stiamo costruendo !</p>
                                    <br />
                                    <p>Inizia a risparmiare sulla bolletta e  aiuta a salvaguardare l’ambiente.</p>
                                    <br/>
                                    <p><b>L’ENERGIA DEL FUTURO, PARTENDO DA OGGI</b></p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container className="text-center py-5 my-5">
                <div className="mb-5">
                    <h2 className="fw-bold">I vantaggi del fotovoltaico MGM Solar</h2>
                </div>
                <Row>
                    <Col xs={12} md={6} lg={3} className="mb-5 mb-md-5 mb-lg-0">
                        <div className="cardBorder4Corners">
                            <p><b>Risparmio in bolletta</b></p>
                            <br/>
                            <p>
                                Produci fino al 90% dell'energia che consumi e rivendi quella che non usi.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-5 mb-md-5 mb-lg-0">
                        <div className="cardBorder4Corners">
                            <p><b>Basso impatto ambientale</b></p>
                            <br/>
                            <p>
                                Produci la tua energia pulita e diminuisci la tua impronta sull'ambiente.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-5 mb-md-0">
                        <div className="cardBorder4Corners">
                            <p><b>Agevolazioni fiscali</b></p>
                            <br/>
                            <p>
                                Recuperi il 50% sul tuo impianto con la cessione del credito.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <div className="cardBorder4Corners">
                            <p><b>Soluzioni chiavi in mano</b></p>
                            <br/>
                            <p>
                                Pensiamo a tutto noi: dalla verifica alla manutenzione dell'impianto.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    )
}
