
export function VideoBG({src, className, id}) {
    return (
        <div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${src}"
          class="${className}"
        />,
      ` }}>

        </div>
    )
}
