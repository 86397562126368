import {Navbar} from "../Navbar/Navbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import ImgFrameVerde from "../imgs/frame_green.png";
import {
    FaChartBar,
    FaDollarSign,
    FaGlobeAmericas,
    FaHammer,
    FaRuler, FaScroll,
    FaSolarPanel,
    FaStar,
    FaStickyNote
} from "react-icons/fa";
import {Footer} from "../Footer/Footer";
import {MetaDecorator} from "../utils/MetaDecorator";


export function ChiSiamo() {

    return (
        <div>
            <MetaDecorator
                title="Chi Siamo"
                description="MGM solar nasce dopo 15 anni di esperienza nel settore delle energie rinnovabili. Oggi è specializzata nella progettazione, installazione e collaudo di impianti fotovoltaici di grossa taglia."
                keywords="chi siamo, azienda, storia societa, società, societa, mgm solar storia, mgm solar chi siamo"
            />
            <Navbar />
            <div className="py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container className="py-5">
                    <Container className="py-5">
                        <Row>
                            <Col className="text-start d-flex justify-content-start align-items-center">
                                <div>
                                    <h1 className="fw-bold text-center text-lg-start mb-5">Chi Siamo</h1>
                                    <p className="mb-5">
                                        <p>MGM solar nasce dopo 15 anni di esperienza nel settore delle energie rinnovabili. Oggi è specializzata in <b>progettazione, installazione e collaudo di impianti fotovoltaici di grossa taglia</b>.</p>
                                        <br/>
                                        <p>Grazie a un team di oltre 50 persone Mgm Solar accompagna ogni i clienti in tutte le fasi garantendo inoltre un servizio professionale di <b>o&m manutenzione</b> e <b>assistenza post-vendita</b>.</p>
                                        <br/>
                                        <p>Le alte prestazioni e l’affidabilità nel tempo hanno permesso a Mgm Solar di realizzare oltre 1000 impianti fotovoltaici fornendo sempre la soluzione su misura in grado di tagliare i costi legati all’approvvigionamento energetico.</p>
                                    </p>
                                </div>
                            </Col>
                            <Col md={5} className="d-flex justify-content-end align-items-center d-none d-lg-block">
                                <img
                                    src="https://i.ibb.co/JtsxzbM/chisiamo-pic.png"
                                    alt="Chi Siamo"
                                    style={{width:'35vw'}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
            <div className="py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh', backgroundColor:'#c3cbc9'}}>
                <Container>
                    <div className="mb-5">
                        <h3 className="fw-bold mb-3">Il Team</h3>
                        <p>MGM Solar accompagna il cliente in tutte le fasi, grazie a una squadra di professionisti specializzati.</p>
                    </div>
                    <Row className="text-start">
                        <Col xs={12} md={6} className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-5 mb-lg-4">
                            <div>
                                <FaChartBar
                                    size="3em"
                                    className="text-green mb-3 mb-lg-0"
                                />
                            </div>
                            <div className="mx-4 text-center text-lg-start">
                                <h5 className="mb-2 fw-bold">Consulenza energetica</h5>
                                <p>Grazie a un’analisi preliminare e gratuita,
                                    valutiamo insieme le migliori soluzioni
                                    per le tue esigenze di consumo.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-5 mb-lg-4">
                            <div>
                                <FaScroll
                                    size="3em"
                                    className="text-green mb-3 mb-lg-0"
                                />
                            </div>
                            <div className="mx-4 text-center text-lg-start">
                                <h5 className="mb-2 fw-bold">Gestione pratiche</h5>
                                <p>Ci occupiamo del rapporto con gli enti
                                    preposti per l’esecuzione dei lavori e per l’accesso a contributi, incentivi, detrazioni e finanziamenti agevolati</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-5 mb-lg-4">
                            <div>
                                <FaRuler
                                    size="3em"
                                    className="text-green mb-3 mb-lg-0"
                                />
                            </div>
                            <div className="mx-4 text-center text-lg-start">
                                <h5 className="mb-2 fw-bold">Ufficio Tecnico</h5>
                                <p>L’Ufficio Tecnico sviluppa i progetti esecutivi selezionando le migliori tecnologie presenti sul mercato e ti assegna un referente unico: il project manager.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-5 mb-lg-4">
                            <div>
                                <FaHammer
                                    size="3em"
                                    className="text-green mb-3 mb-lg-0"
                                />
                            </div>
                            <div className="mx-4 text-center text-lg-start">
                                <h5 className="mb-2 fw-bold">Installazione e Assistenza</h5>
                                <p>I nostri specialisti tecnici e idraulici possiedono tutte le qualifiche e le certificazioni necessarie per installare sistemi e impianti per l’efficienza energetica.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-green py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'30vh'}}>
                <Container className="py-5 text-light">
                    <p className="mb-3 fst-italic">La nostra Mission.</p>
                    <h3 className="fw-bold fst-italic">
                        Innoviamo il Presente guardando sempre al Futuro.
                    </h3>
                </Container>
            </div>
            <div>
                <Container>
                    <Container className="py-5 my-5">
                        <Row>
                            <Col xs={12} md={6} className="text-center px-5 mb-5 mb-md-0">
                                <h3 className="fw-bold mb-3">Soluzione energetica</h3>
                                <p>Il nostro obiettivo è quello di trovare le migliori soluzioni per permettere a ogni cliente di raggiungere l’indipendenza energetica realizzando impianti fotovoltaici su misura per lui.</p>
                            </Col>
                            <Col xs={12} md={6} className="text-center px-5">
                                <h3 className="fw-bold mb-3">Soluzione finanziaria</h3>
                                <p>MGM SOLAR finanzierà il tuo investimento al posto della banca attraverso la formula del noleggio operativo con riscatto.</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>

            <div className="bg-green py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container className="py-5">
                    <div className="mb-5 text-light">
                        <h2 className="mb-2">Operiamo nel Fotovoltaico da oltre <b><u>15 anni</u></b></h2>
                    </div>
                    <Row className="pt-5">
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center mb-5 mb-lg-0">
                            <div className="text-light">
                                <FaStar size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Anni attività</p>
                                <h2 className="fw-bold">15+</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center mb-5 mb-lg-0">
                            <div className="text-light">
                                <FaSolarPanel size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Impianti installati</p>
                                <h2 className="fw-bold">1,5k</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center mb-5 mb-md-0">
                            <div className="text-light">
                                <FaDollarSign size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Risparmio annuo</p>
                                <h2 className="fw-bold">3 MLN €</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="d-flex justify-content-center align-items-center">
                            <div className="text-light">
                                <FaGlobeAmericas size="4em" className="mb-4" />
                                <p className="text-uppercase mb-1 fw-bold">Risparmio CO2</p>
                                <h2 className="fw-bold">30k T</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="py-5 text-center d-flex justify-content-center align-items-center">
                <Container className="py-5">
                    <p className="fst-italic mb-3">La nostra Vision.</p>
                    <h4 className="mb-3">
                        Il fotovoltaico è l’unica soluzione per accelerare il passaggio all’energia sostenibile nel mondo, salvaguardando questo pianeta e restituendo ai nostri figli un mondo migliore.
                    </h4>
                    <h4 >
                        È l'alternativa più semplice da scegliere, perché trasforma la straordinaria forza del sole in energia, pulita e sempre disponibile.
                    </h4>
                </Container>
            </div>

            <Footer />
        </div>
    )
}
