import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import firebase from "firebase";
import {useLocation} from "react-router";
import {IconPickerItem} from "react-fa-icon-picker";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function ItemRealizzazione() {

    const path = useLocation().pathname.split('/')
    const pathid = path[path.length-1]

    const [data, setData] = useState({
        titolo: '',
        fotoCover: '',
        testo: '',
        datigenerali: [],
        datitecnici: [],
        foto: [],
        video: '',
        id: '',
    })
    const [arrfoto, setArrfoto] = useState([])
    useEffect(() => {
        firebase.firestore().collection('realizzazioni').doc(pathid).onSnapshot((snapshot) => {
            setData(snapshot.data())

            let prv = snapshot.data().foto
            if(prv.length>=2) {
                prv.shift()
                setArrfoto(prv)
            }
        })
    }, [])


    return (
        <div>
            <MetaDecorator
                title={data.titolo}
                description={data.testo}
                keywords={data.titolo}
            />
            <Navbar />
            <div>
                <Container fluid className="my-5">
                    <Container style={{minHeight:'30vh'}}>
                        <Row>
                            <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center mb-5 mb-lg-0">
                                <div className="d-flex justify-content-center">
                                    <div
                                        style={{
                                            backgroundImage:`url(${data.fotoCover})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                        className="imgRealizzazioni"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h2 className="fw-bold mb-4">{data.titolo}</h2>
                                    {
                                        data.datigenerali ?
                                            data.datigenerali.map(a => <div className="d-flex justify-content-center align-items-center">
                                                <div>
                                                    <IconPickerItem icon={a.icon} size={28} color="#000" />
                                                </div>
                                                <div>
                                                    <span>&nbsp;&nbsp;{a.label}: {a.desc}</span>
                                                </div>
                                            </div>)
                                            :
                                            null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="bg-green py-5 text-light mb-5">
                    <Container>
                        <Row>
                            {
                                data.datitecnici ?
                                    <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5 mb-md-0">
                                        <div className="text-center">
                                            <h3 className="fw-bold mb-3">Dati tecnici</h3>
                                            {
                                                data.datitecnici.map(a => <div>
                                                    <b>{a.label}</b>: {a.desc}
                                                </div>)
                                            }
                                        </div>
                                    </Col>
                                    :
                                    null
                            }
                            <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                                <div className="text-justify">
                                    {data.testo}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {
                    data.foto ?
                        data.foto.length!==0 ?
                            <Container className="p-5 mt-5">
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <img
                                        src={data.foto[0]}
                                        alt="Foto 01"
                                        style={{width: '100%'}}
                                        className="imgsRealizzazioni"
                                    />
                                </div>
                                {
                                    data.foto.length>=2 ?
                                        <Row>
                                            {
                                                arrfoto.length!==0 ?
                                                    arrfoto.map(x =>
                                                        <Col md={6} className="p-3">
                                                            <img
                                                                src={x}
                                                                alt="Foto 01"
                                                                style={{width: '100%'}}
                                                                className="imgsRealizzazioni"
                                                            />
                                                        </Col>
                                                    )
                                                    :
                                                    null
                                            }
                                        </Row>
                                        :
                                        null
                                }
                            </Container>
                            :
                            null
                        :
                        null
                }
                {
                    data.video!=='' ?
                        <Container className="mb-5">
                            <div
                                style={{
                                    position: 'relative',
                                    paddingBottom: '56.25%',
                                    height: 0,
                                }}
                            >
                                <iframe
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    src={"https://www.youtube.com/embed/" + getYoutubeId(data.video)}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        </Container>
                        :
                        null
                }
            </div>
            <Footer />
        </div>
    )

    function getYoutubeId(url){
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return (match&&match[7].length===11)? match[7] : false;
    }
}
