import {Button, Col, Form, FormControl, Row} from "react-bootstrap";
import {useState} from "react";
import firebase from "firebase";
import {nanoid} from "nanoid";
import {IconPicker, IconPickerItem} from "react-fa-icon-picker";
import {FaTimes} from "react-icons/fa";


export function AggiungiRealizzazione() {


    const emptyData = {
        titolo: '',
        fotoCover: '',
        testo: '',
        datigenerali: [],
        datitecnici: [],
        foto: [],
        video: '',
    }

    const [data, setData] = useState(emptyData)

    const [input1, setInput1] = useState({
        icon: '',
        label: '',
        desc: '',
    })
    const [input2, setInput2] = useState({
        label: '',
        desc: '',
    })
    const [input3, setInput3] = useState('')

    const [result, setResult] = useState('')


    return (
        <div>
            <div className="mb-5">
                <h3 className="fw-bold mb-3">Nuova Realizzazione</h3>
            </div>
            <div>

                <div className="mb-4">
                    <h5 className="fw-bold mb-2">Informazioni</h5>
                    <Form.Group className="mb-2">
                        <Form.Label className="small m-1">Titolo *</Form.Label>
                        <Form.Control
                            type="text"
                            className="rounded10"
                            placeholder="Titolo realizzazione"
                            value={data.titolo}
                            onChange={e => setData({
                                ...data,
                                titolo: e.target.value,
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className="small m-1">Foto cover *</Form.Label>
                        <Form.Control
                            type="link"
                            className="rounded10"
                            placeholder="Link foto principale"
                            value={data.fotoCover}
                            onChange={e => setData({
                                ...data,
                                fotoCover: e.target.value,
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className="small m-1">Testo *</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            className="rounded10"
                            placeholder="Testo descrittivo"
                            value={data.testo}
                            onChange={e => setData({
                                ...data,
                                testo: e.target.value,
                            })}
                        />
                    </Form.Group>
                </div>

                <div className="mb-4">
                    <h5 className="fw-bold mb-2">Dati generali</h5>

                    <ul>
                        {
                            data.datigenerali.length!==0 ?
                                data.datigenerali.map(a => <li className="d-flex justify-content-start align-items-center">
                                    <IconPickerItem icon={a.icon} size={18} /> <span> &nbsp;&nbsp;{a.label}: <b>{a.desc}</b></span>
                                    <span>&nbsp;&nbsp;<FaTimes
                                        className="iconHover"
                                        size="1.2em"
                                        color="#ff0000"
                                        onClick={() => {
                                            if(window.confirm('Eliminare questa voce?')) {
                                                setData({
                                                    ...data,
                                                    datigenerali: data.datigenerali.filter(x => x.icon!==a.icon && x.label!==a.label && x.desc!==a.desc)
                                                })
                                            }
                                        }}
                                    /></span>
                                </li>)
                                :
                                <li>
                                    <i className="fw-bold opacity-50 text-decoration-underline">Nessun dato aggiunto</i>
                                </li>
                        }
                    </ul>

                    <Row>
                        <Col md={2}>
                            <Form.Group className="mb-2">
                                <Form.Label className="small m-1">Icona</Form.Label>
                                {
                                    /**

                                     <Form.Control
                                     type="text"
                                     className="rounded10"
                                     placeholder="Scegli un'icona"
                                     value={input1.icon}
                                     onChange={e => setInput1({
                                        ...input1,
                                        icon: e.target.value
                                    })}
                                     />

                                     */
                                }
                                <IconPicker
                                    value={input1.icon}
                                    onChange={e => setInput1({
                                        ...input1,
                                        icon: e
                                    })}
                                    buttonStyles={{
                                        borderRadius: 10,
                                        width:'100%',
                                        backgroundColor: '#fff',
                                        borderColor:'#cbcbcb'
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-2">
                                <Form.Label className="small m-1">Label</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10"
                                    placeholder="Label"
                                    value={input1.label}
                                    onChange={e => setInput1({
                                        ...input1,
                                        label: e.target.value
                                    })}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-2">
                                <Form.Label className="small m-1">Descrizione</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10"
                                    placeholder="Descrizione"
                                    value={input1.desc}
                                    onChange={e => setInput1({
                                        ...input1,
                                        desc: e.target.value
                                    })}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end align-items-end">
                            <Button
                                className="rounded10 mb-2"
                                disabled={input1.icon.trim()==='' || input1.label.trim()==='' || input1.desc.trim()===''}
                                onClick={() => {
                                    setData({
                                        ...data,
                                        datigenerali: [
                                            ...data.datigenerali,
                                            {
                                                icon: input1.icon,
                                                label: input1.label,
                                                desc: input1.desc,
                                            }
                                        ]
                                    })
                                    setInput1({
                                        icon: '',
                                        label: '',
                                        desc: '',
                                    })
                                }}
                            >
                                Aggiungi
                            </Button>
                        </Col>
                    </Row>
                </div>


                <div className="mb-4">
                    <h5 className="fw-bold mb-2">Dati tecnici</h5>

                    <ul>
                        {
                            data.datitecnici.length!==0 ?
                                data.datitecnici.map(a => <li>
                                    {a.label}: <b>{a.desc}</b>
                                    <span>&nbsp;&nbsp;<FaTimes
                                        className="iconHover"
                                        size="1.2em"
                                        color="#ff0000"
                                        onClick={() => {
                                            if(window.confirm('Eliminare questa voce?')) {
                                                setData({
                                                    ...data,
                                                    datitecnici: data.datitecnici.filter(x => x.label!==a.label && x.desc!==a.desc)
                                                })
                                            }
                                        }}
                                    /></span>
                                </li>)
                                :
                                <li>
                                    <i className="fw-bold opacity-50 text-decoration-underline">Nessun dato aggiunto</i>
                                </li>
                        }
                    </ul>

                    <Row>
                        <Col>
                            <Form.Group className="mb-2">
                                <Form.Label className="small m-1">Label</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10"
                                    placeholder="Label"
                                    value={input2.label}
                                    onChange={e => setInput2({
                                        ...input2,
                                        label: e.target.value
                                    })}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-2">
                                <Form.Label className="small m-1">Descrizione</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="rounded10"
                                    placeholder="Descrizione"
                                    value={input2.desc}
                                    onChange={e => setInput2({
                                        ...input2,
                                        desc: e.target.value
                                    })}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end align-items-end">
                            <Button
                                className="rounded10 mb-2"
                                disabled={input2.label.trim()==='' || input2.desc.trim()===''}
                                onClick={() => {
                                    setData({
                                        ...data,
                                        datitecnici: [
                                            ...data.datitecnici,
                                            {
                                                label: input2.label,
                                                desc: input2.desc,
                                            }
                                        ]
                                    })
                                    setInput2({
                                        label: '',
                                        desc: '',
                                    })
                                }}
                            >
                                Aggiungi
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className="mb-4">
                    <h5 className="fw-bold mb-2">Foto e video</h5>

                    <ul>
                        {
                            data.foto.length!==0 ?
                                data.foto.map(a => <li>
                                    <a href={a} target="_blank" rel="noreferrer">LINK</a>
                                    <span>&nbsp;&nbsp;<FaTimes
                                        className="iconHover"
                                        size="1.2em"
                                        color="#ff0000"
                                        onClick={() => {
                                            if(window.confirm('Eliminare questa voce?')) {
                                                setData({
                                                    ...data,
                                                    foto: data.foto.filter(x => x!==a)
                                                })
                                            }
                                        }}
                                    /></span>
                                </li>)
                                :
                                <li>
                                    <i className="fw-bold opacity-50 text-decoration-underline">Nessuna foto aggiunta</i>
                                </li>
                        }
                    </ul>

                    <Row>
                        <Col>
                            <Form.Group className="mb-2">
                                <Form.Label className="small m-1">Foto</Form.Label>
                                <Form.Control
                                    type="link"
                                    className="rounded10"
                                    placeholder="Link foto"
                                    value={input3}
                                    onChange={e => setInput3(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end align-items-end">
                            <Button
                                className="rounded10 mb-2"
                                disabled={input3.trim()===''}
                                onClick={() => {
                                    setData({
                                        ...data,
                                        foto: [
                                            ...data.foto,
                                            input3,
                                        ]
                                    })
                                    setInput3('')
                                }}
                            >
                                Aggiungi
                            </Button>
                        </Col>
                    </Row>

                    <Form.Group className="mb-2">
                        <Form.Label className="small m-1">Video YouTube</Form.Label>
                        <Form.Control
                            type="link"
                            className="rounded10"
                            placeholder="Link video YouTube"
                            value={data.video}
                            onChange={e => setData({
                                ...data,
                                video: e.target.value,
                            })}
                        />
                    </Form.Group>

                </div>

                <div className="text-end">
                    {
                        result ?
                            <span className="fw-bold mx-2">{result}&nbsp;</span>
                            :
                            null
                    }
                    <Button
                        className="rounded10"
                        variant="primary"
                        disabled={data.titolo.trim()==='' || data.fotoCover.trim()==='' || data.testo.trim()===''}
                        onClick={() => {
                            const id = nanoid(10)
                            firebase.firestore().collection('realizzazioni').doc(id).set({
                                ...data,
                                id: id,
                            }).then(() => {
                                setResult('Realizzazione aggiunta con successo')
                                setData(emptyData)
                            }).catch(() => {
                                setResult('Qualcosa è andato storto. Controlla i dati e riprova.')
                            })
                        }}
                    >
                        Pubblica
                    </Button>
                </div>

            </div>
        </div>
    )
}
