import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./Pages/Home/Home";
import {ChiSiamo} from "./Pages/ChiSiamo";
import {Contatti} from "./Pages/Contatti";
import {Manutenzione} from "./Pages/Assistenza/Manutenzione";
import {Pratiche} from "./Pages/Assistenza/Pratiche";
import {SistemaAccumulo} from "./Pages/Servizi/SistemaAccumulo";
import {RimozioneEternit} from "./Pages/Servizi/RimozioneEternit";
import {Industria} from "./Pages/Industria";
import {AziendeAgricole} from "./Pages/AziendeAgricole";
import {FotovoltaicoSuCopertura} from "./Pages/Servizi/FotovoltaicoSuCopertura";
import {FotovoltaicoATerra} from "./Pages/Servizi/FotovoltaicoATerra";
import {FotovoltaicoSuParcheggio} from "./Pages/Servizi/FotovoltaicoSuParcheggio";
import {NotFound404} from "./Pages/NotFound404";
import {AreaRiservata} from "./AreaRiservata/AreaRiservata";
import {SerramentiEdInfissi} from "./Pages/Casa/SerramentiEdInfissi";
import {Fotovoltaico} from "./Pages/Casa/Fotovoltaico";
import {IspezionePannelliConDrone} from "./Pages/Assistenza/IspezionePannelliConDrone";
import {Realizzazioni} from "./Pages/Realizzazioni/Realizzazioni";
import {ItemRealizzazione} from "./Pages/Realizzazioni/ItemRealizzazione";
import {ColonnineRicarica} from "./Pages/Servizi/ColonnineRicarica";
import {useLocation} from "react-router";
import {useEffect} from "react";
import {NoleggioOperativo} from "./Pages/Servizi/NoleggioOperativo";


export function Router() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/chi-siamo" element={<ChiSiamo />} />

                        <Route exact path="/servizi/fotovoltaico-su-copertura" element={<FotovoltaicoSuCopertura />} />
                        <Route exact path="/servizi/fotovoltaico-a-terra" element={<FotovoltaicoATerra />} />
                        <Route exact path="/servizi/fotovoltaico-su-parcheggio" element={<FotovoltaicoSuParcheggio />} />
                        <Route exact path="/servizi/colonnine-ricarica" element={<ColonnineRicarica />} />
                        <Route exact path="/servizi/rimozione-eternit" element={<RimozioneEternit />} />
                        <Route exact path="/servizi/sistema-accumulo" element={<SistemaAccumulo />} />
                        <Route exact path="/servizi/noleggio-operativo" element={<NoleggioOperativo />} />

                        <Route exact path="/industria" element={<Industria />} />
                        <Route exact path="/aziende-agricole" element={<AziendeAgricole />} />

                        <Route exact path="/casa/fotovoltaico" element={<Fotovoltaico />} />
                        <Route exact path="/casa/serramenti-ed-infissi" element={<SerramentiEdInfissi />} />

                        <Route exact path="/assistenza/manutenzione" element={<Manutenzione />} />
                        <Route exact path="/assistenza/pratiche" element={<Pratiche />} />
                        <Route exact path="/assistenza/ispezione-pannelli-con-drone" element={<IspezionePannelliConDrone />} />

                        <Route exact path="/realizzazioni" element={<Realizzazioni />} />
                        <Route path="/realizzazioni/:id" element={<ItemRealizzazione />} />

                        <Route exact path="/contatti" element={<Contatti />} />

                        {
                            // AREA RISERVATA
                        }
                        <Route path="/area-riservata" element={<AreaRiservata />} />

                        {
                            // 404
                        }
                        <Route path="*" element={<NotFound404 />} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </>
    )

    function ScrollToTop(props) {
        const location = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location]);

        return <>{props.children}</>
    };
}
